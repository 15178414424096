<script>
import Layout from "@/router/layouts/auth";
import { mapState, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import Languages from "@/components/widgets/languages";
import Password from "@/components/widgets/input-password";

export default {
  locales: {
    pt: {
      "E-mail": "E-mail",
      "E-mail is required.": "E-mail é obrigatório",
      Password: "Senha",
      "Log In": "Entrar",
      "The e-mail and/or password is incorrect.":
        "O usuário e/ou a senha estão incorretos.",
      "The login was successful.": "O login foi efetuado com sucesso.",
      "Forgot your password?": "Esqueceu sua senha?",
      "Don't have an account?": "Não possui uma conta?",
      "Signup now": "Cadastre-se",
    },
    es: {
      "E-mail": "E-mail",
      "E-mail is required.": "E-mail é obrigatório",
      Password: "Contraseña",
      "Log In": "Iniciar sesión",
      "The e-mail and/or password is incorrect.":
        "El nombre de usuario y/o contraseña son incorrectos.",
      "The login was successful.": "El inicio de sesión fue exitoso.",
      "Forgot your password?": "¿Olvidaste tu contraseña?",
      "Don't have an account?": "¿No tienes una cuenta?",
      "Signup now": "Regístrate ahora",
    },
  },
  components: {
    Layout,
    Languages,
    Password,
  },
  data() {
    return {
      language: "en",

      email: "",
      password: "",
      loading: false,
      submitted: false,

      user: {
        name: "",
        email: "",
        avatar: "",
      },
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      alert: (state) => state.alert,
    }),
  },
  validations: {
    email: { required },
    password: { required, minLength: minLength(6) },
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapActions("account", ["login", "logout"]),
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const { email, password } = this;
        if (email && password) {
          this.alert.type = "";
          this.alert.message = "";
          this.loading = true;
          this.$v.$reset();
          this.login({ email, password });
        }
      }
    },
    clearUser() {
      this.email = "";
      this.user.name = "";
      this.user.email = "";
      this.user.avatar = "";
    },
  },
  mounted() {
    if (this.$store.state.account.user) {
      this.email = this.$store.state.account.user.email;
      this.user.name = this.$store.state.account.user.name;
      this.user.email = this.$store.state.account.user.email;
      this.user.avatar = this.$store.state.account.user.avatar;
    }
  },
  watch: {
    "alert.type": function (alert) {
      if (alert == "alert-danger") {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="d-flex">
      <div class="login-content">
        <div class="pt-5 pb-2 text-center">
          <router-link class="align-middle" tag="a" to="/">
            <img class="w-100" style="max-width: 200px" src="@/assets/images/logo.png" />
          </router-link>
        </div>
        <div class="py-5">
          <b-form @submit.prevent="handleSubmit">
            <div v-if="alert.message" :class="'alert ' + alert.type">
              {{ t(alert.message) }}
            </div>
            <b-form-group :label="t('E-mail')">
              <b-form-input id="input-1" v-model="email" type="text" :class="{ 'is-invalid': submitted && $v.email.$error }"></b-form-input>
              <div v-if="submitted && !$v.email.required" class="invalid-feedback">
                {{ t("E-mail is required.") }}
              </div>
            </b-form-group>
            <b-form-group :label="t('Password')">
              <Password v-model="password" :v="$v.password"></Password>
            </b-form-group>
            <div class="mt-4">
              <b-button :disabled="loading == true" type="submit" variant="default" class="btn-lg btn-block">
                {{ t("Log In") }}
                <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </div>
            <div class="mt-4">
              <router-link tag="a" to="/login/recuperar-senha" class="text-muted">
                <i class="mdi mdi-lock mr-1"></i>
                {{ t("Forgot your password?") }}
              </router-link>
            </div>
          </b-form>
          <div class="mt-2">
            <Languages class="d-none" type="dropup"></Languages>
          </div>
        </div>
      </div>
      <div class="flex-fill login-image d-none d-lg-block"></div>
    </div>
  </Layout>
</template>